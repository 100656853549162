import logo from './logo.svg'
import './App.scss'

// Components
import MainLayout from './layouts/MainLayout'
import Home from './components/Home'
import Contacts from './components/Contacts'
import About from './components/About'
import ScrollToTopOnPageChange from './components/ScrollToTopOnPageChange'
import PrivacyPolicy from './components/PrivacyPolicy'

// Context
import PopupContext from './context/popupContext'
import ProductContext from './context/productContext'

// Data
import products from './data/products.json'

// React
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useState } from 'react'



function App() {
    const [currentPopup, setCurrentPopup] = useState({ content: '', isActive: false })
    const [currentProducts, setProducts] = useState([products])

    return (
        <BrowserRouter>
            <ScrollToTopOnPageChange />
            <PopupContext.Provider value={{ currentPopup, setCurrentPopup }}>
                <ProductContext.Provider value={{ currentProducts, setProducts }}>
                    <div className="App">
                        <Routes>
                            <Route path="/" element={<MainLayout />}>
                                <Route index={true} element={<Home />} />
                                <Route path="/contacts" element={<Contacts />} />
                                <Route path="/about" element={<About />} />
                                
                                <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
                            </Route>
                        </Routes>
                    </div>
                </ProductContext.Provider>
            </PopupContext.Provider>
        </BrowserRouter>
    )
}

export default App
