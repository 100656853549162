import React from 'react'
import { motion, AnimationProps } from 'framer-motion'

const shapeVariations: AnimationProps = {
    transition: {
        delay: 1,
        duration: 0.5,
        ease: 'easeIn',
    },
    variants: {
        visible: {
            fillOpacity: 1,
            pathLength: 1,
            transition: {
                delay: 0.5,
                duration: 1,
            },
        },
        hidden: {
            fillOpacity: 0,
            pathLength: 0,
        },
    },
}

const mainPathVariant: AnimationProps = {
    transition: {
        duration: 1,
        ease: 'easeInOut',
    },
    variants: {
        visible: {
            pathLength: 1,
        },
        hidden: {
            pathLength: 0,
        },
    },
}

function GlassCarnis({className, color1, color2, color3, color4, colorRect}) {
    const stokeStairColor = color1
    const fillStailrColor = color2

    return (
        <svg fill="none" className={className} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 150 150">
            <g clipPath="url(#clip0_63_16)">
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                   d="M90 63L147 87.5L107 104.167L57 125L-9.5 91.5L3.5 87.5V79.5L35 70.6406L67.5 61.5L78.5 66.5L90 63Z"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                    stroke={stokeStairColor}
                    // stroke={stokeStairColor}
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M107 104.167L147 87.5L90 63L78.5 66.5L67.5 61.5L35 70.6406M107 104.167L57 125L-9.5 91.5L3.5 87.5V79.5L35 70.6406M107 104.167L35 70.6406"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                    stroke={stokeStairColor}
                    fill={fillStailrColor}
                    // stroke={stokeStairColor}
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M55.5 119.5L56.5 112L3 24M96.5 103V97.5L101.5 95.5M101.5 95.5L30 -4M101.5 95.5L105.5 93.5V99"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                    stroke={stokeStairColor}
                    // stroke={stokeStairColor}
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M138 86L139.5 80.5L80.5 -12" 
                    strokeMiterlimit="10"
                    strokeWidth="3"
                    stroke={stokeStairColor}
                    // stroke={stokeStairColor}
                />
                
                <motion.rect
                    x="5"
                    y="5"
                    width="140"
                    height="140"
                    rx="11"
                    stroke={colorRect}
                    strokeWidth="10"
                    fill="none"
                    {...mainPathVariant}
                    initial="hidden"
                    animate="visible"
                />
            </g>
            <defs>
                <clipPath id="clip0_63_16">
                    <rect width="150" height="150" rx="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default GlassCarnis
