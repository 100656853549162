import React from 'react'
import Button from './Button'

import { useEffect, useState, useContext } from 'react'

import axios from 'axios'
import products from '../data/products.json'
import { v4 } from 'uuid'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/bundle'

import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import './WhatWeDo.scss'

import PopupContext from '../context/popupContext'

//icons
import Floor from './icons/Floor'
import GlassCarnis from './icons/GlassCarnis'
import Stair from './icons/Stair'
import Roof from './icons/Roof'

function WhatWeDo() {
    // console.log(Swiper)
    const [activeProduct, setActiveProduct] = useState(products[0])
    const [imagePaths, setImagePaths] = useState([])

    function setProductHandler(newProduct) {
        // console.log(activeProduct)
        setActiveProduct(newProduct)
    }

    const { currentPopup, setCurrentPopup } = useContext(PopupContext)

    let currentIcon
    switch (activeProduct.name) {
        case 'Стеклянные козырьки':
            currentIcon =  <GlassCarnis className="icon-container__icon" color1='#FF9914' color2='rgba(217,217,217, .3)' colorRect='#3D3D3D'/>
            break
        case 'Металлические лестницы':
            currentIcon =  <Stair className="icon-container__icon" color1='#3D3D3D' color2='#FF9914' colorRect='#3D3D3D'/>
            break
        case 'Антресольные этажи':
            currentIcon = <Floor className="icon-container__icon" color1='#3D3D3D' color2='#FF9914' color3='#FF9914' color4='#FF9914' colorRect='#3D3D3D'/>
            break

            case 'Навесы':
            currentIcon = <Roof className="icon-container__icon" color1='#3D3D3D' color2='#FF9914' color3='#3D3D3D' color4='#FF9914' colorRect='#3D3D3D'/>
            break
        default:
            currentIcon = null // или другой компонент по умолчанию
    }

    useEffect(() => {
        function importAll(r) {
            // console.log(r)

            let images = {}
            r.keys().map((item, index) => {
                images[item.replace('./', '')] = r(item)
            })
            return images
        }

        const images = importAll(require.context('../images/products/', true, /\.(gif|jpe?g|svg)$/))

        const newImages = Object.keys(images)
            .filter((el) => {
                const firstPart = el.split('/')[0]
                return firstPart == activeProduct.id
            })
            .map((el) => images[el])

        setImagePaths(newImages)
    }, [activeProduct])

    useEffect(() => {}, [activeProduct])

    function openTheImageHandler(link) {
        const image = <img src={link} />

        setCurrentPopup({ content: image, isActive: true })
    }

    return (
        <div className="what-we-do-container">
            <div className="what-we-do-container__controls">
                <div>
                    <h2>Что мы делаем?</h2>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed amet incidunt ipsam a, culpa assumenda, officiis quos unde tempora nemo in
                        aliquam quia neque architecto rerum, fugiat officia error autem.
                    </p>
                </div>

                <div className="buttons">
                    {products.map((el, index) => {
                        let colorOfButton
                        if (el === activeProduct) {
                            colorOfButton = 'orange'
                        } else {
                            colorOfButton = 'black-grey btn--inactive'
                        }

                        return <Button el={el} color={colorOfButton} key={`buttons--${index}`} onClickFunction={setProductHandler} />
                    })}
                </div>
            </div>{' '}
            <br />
            <hr />
            <br />
            <div className="products-info">
                {activeProduct ? (
                    <div>
                        <div className="product-info__info-container">
                            <div className="product-info__main">
                                <div className="product-info__header">{currentIcon}<h2>{activeProduct.name}</h2></div>
                                
                                <br />
                                <p>
                                    {activeProduct.description.split('\n\n').map((text, index) => (
                                        <span key={index}>
                                            {text}
                                            <br />
                                            <br />
                                        </span>
                                    ))}
                                </p>
                            </div>

                            <div className="product-info__additional"></div>
                        </div>
                        <br />

                        {imagePaths.length > 0 ? (
                            <Swiper
                                spaceBetween={100}
                                slidesPerView={1}
                                className="slider-of-products-photo"
                                loop={true}
                                modules={[Autoplay, Pagination]}
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: true,
                                }}
                                pagination={{
                                    enabled: true,
                                }}
                                breakpoints={{
                                    700: {
                                        slidesPerView: 2,
                                        spaceBetween: 200,
                                        pagination: {
                                            enabled: false,
                                        },
                                    },
                                }}
                            >
                                {imagePaths.map((el, index) => {
                                    // console.log(el)
                                    return (
                                        <SwiperSlide key={`image-slide--${index}`}>
                                            <div
                                                style={{ backgroundImage: `url(${el})`, color: 'red' }}
                                                loading="lazy"
                                                className="slider-of-products-photo__photo"
                                                onClick={(evt) => {
                                                    openTheImageHandler(el)
                                                }}
                                            ></div>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        ) : (
                            ''
                        )}
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    )
}

export default WhatWeDo
