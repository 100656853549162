import Menu from '../components/Menu'
import Footer from '../components/Footer'
import Popup from '../components/Popup'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'
import PopupContext from '../context/popupContext'

import { Outlet, useLocation } from 'react-router-dom'
import { useContext, useState, useEffect } from 'react'

import { GiHamburgerMenu } from 'react-icons/gi'
import { IoCloseOutline } from 'react-icons/io5'

import UAParser from 'ua-parser-js'

import { YMInitializer } from 'react-yandex-metrika'

// Функция для определения, является ли пользовательский агент ботом
function isBot() {
    const parser = new UAParser()
    const result = parser.getResult()
    const userAgent = result.ua.toLowerCase()

    // Простая проверка на ботов
    const botKeywords = ['bot', 'crawl', 'spider', 'slurp']

    //   console.log(botKeywords.some(keyword => userAgent.includes(keyword)))

    return botKeywords.some((keyword) => userAgent.includes(keyword))
}



function MainLayout() {
    const { currentPopup, setCurrentPopup } = useContext(PopupContext)

    const handleBackdropClick = () => {
        setCurrentPopup({ content: currentPopup.content, isActive: false })
        setTimeout(() => {
            setCurrentPopup({ content: '', isActive: false })
        }, 300)
    }

    const popupContent = <Menu />

    

    return (
        <>
            <Popup />

            {isMobile && (
                <div
                    className="open-menu-button"
                    onClick={(evt) => {
                        if (!currentPopup.isActive) {
                            setCurrentPopup({ content: popupContent, isActive: true })
                        } else {
                            handleBackdropClick()
                        }
                    }}
                >
                    {currentPopup.isActive ? <IoCloseOutline /> : <GiHamburgerMenu />}
                </div>
            )}

            <div className="container">
                <div className="block block-nav">
                    <div className="block-wrapper">{isBrowser && <Menu />}</div>
                </div>

                <div className="block">
                    <Outlet />
                </div>
                <div className="block">
                    <div className="block-wrapper">
                        <Footer />
                    </div>
                </div>
            </div>

            {!isBot() ? <YMInitializer accounts={[98031267]} options={{ clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true }} /> : ''}
        </>
    )
}

export default MainLayout
