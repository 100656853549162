import React from 'react'

import Form from './Form'

import slogan from '../images/slogan.svg'
import logoUSM from '../images/logo-horizontal-full--light--usm.svg'

import './Contacts.scss'

import { FaPhoneAlt } from 'react-icons/fa'
import { MdOutlineAlternateEmail } from 'react-icons/md'
import { IoLocationOutline } from 'react-icons/io5'

function Contacts() {
    const contactDetails = {
        phone: '+7 (123) 456-7890',
        email: 'info@company.com',
        address: 'Улица Ленина, дом 123, г. Алматы, Казахстан',
    }

    return (
        <div className="block-background home-background" loading="lazy">
            <div className="block-wrapper block-wrapper--home block-wrapper--contacts">
                <div className='contacts-container'>
                    <div className="contacts-header">
                        <h1 className="home-header">
                            <img src={logoUSM} alt="logo ultraSM" className="home-background__logo-usm" />
                            СтальГрад
                        </h1>
                        <img src={slogan} alt="Company Logo -- mini" className="slogan" />
                    </div>
                    
                    <div className="contacts-buttons">
                    <h2>Контакты:</h2> 
                        <div className="contact-item">
                            <FaPhoneAlt />
                            <a href={`tel:${contactDetails.phone}`}>{contactDetails.phone}</a>
                        </div>
                        <div className="contact-item">
                            <MdOutlineAlternateEmail /> <a href={`mailto:${contactDetails.email}`}>{contactDetails.email}</a>
                        </div>
                        <div className="contact-item">
                            <IoLocationOutline />
                            {contactDetails.address}
                        </div>
                    </div>
                </div>

                <Form />
            </div>
        </div>
    )
}

export default Contacts
