import React from 'react'
import { motion, AnimationProps } from 'framer-motion'

const shapeVariations: AnimationProps = {
    transition: {
        delay: 1,
        duration: 0.5,
        ease: 'easeIn',
    },
    variants: {
        visible: {
            fillOpacity: 1,
            pathLength: 1,
            transition: {
                delay: 0.5,
                duration: 1,
            },
        },
        hidden: {
            fillOpacity: 0,
            pathLength: 0,
        },
    },
}

const mainPathVariant: AnimationProps = {
    transition: {
        duration: 1,
        ease: 'easeInOut',
    },
    variants: {
        visible: {
            pathLength: 1,
        },
        hidden: {
            pathLength: 0,
        },
    },
}

function Floor({ className, color1, color2, color3, color4, colorRect }) {
    const stokeStairColor = color1
    const fillStailrColor = color2

    return (
        <svg fill="none" className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150">
            <g clipPath="url(#clip0_63_16)">
                
                
                
                
                <motion.rect
                    x="5"
                    y="5"
                    width="140"
                    height="140"
                    rx="11"
                    stroke={colorRect}
                    strokeWidth="10"
                    fill="none"
                    {...mainPathVariant}
                    initial="hidden"
                    animate="visible"
                />
                {/* Новые пути */}
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M30 126V64M20 123.967L20.5 65.0164M37.5 121.426V70.0984M48 123.459V66.0328"
                    stroke={color3}
                    strokeWidth="2"
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M130.3 135L126.215 134.488L124.684 62.7805L49.1224 70.4634H29.7215V63.8049L19 65.3415V63.8049L29.7215 61.7561L49.1224 62.7805L128.257 51L140 53.0488V62.7805H131.321L132.342 134.488L130.3 135Z"
                    fill={color3}
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M78.9686 136L30.2761 65.7803"
                    stroke={color1}
                    strokeWidth="3"
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M100.496 132.412L53.8535 70.3933"
                    stroke={color1}
                    strokeWidth="3"
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M96.9079 128.312L76.9184 132.412L78.9686 135.487L98.4456 131.387L96.9079 128.312Z"
                    fill={color1}
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M92.8075 122.674L72.3054 125.749L74.3556 128.824L94.3452 125.236L92.8075 122.674Z"
                    fill={color1}
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M88.1945 117.036L67.1799 119.598L69.2301 122.674L89.7322 119.598L88.1945 117.036Z"
                    fill={color1}
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M84.0941 111.91L63.0795 113.96L64.6171 116.523L85.6318 113.96L84.0941 111.91Z"
                    fill={color1}
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M79.9937 106.272L60.0042 108.322L61.5418 110.885L82.0439 108.835L79.9937 106.272Z"
                    fill={color1}
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M76.9184 101.146L56.9288 102.172L58.4665 104.734L78.9686 103.197L76.9184 101.146Z"
                    fill={color1}
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M72.3054 96.5335L52.8284 97.5586L53.341 99.6088L74.3556 98.5837L72.3054 96.5335Z"
                    fill={color1}
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M68.7175 91.9205L49.2405 92.4331L50.2656 94.4833L70.2552 93.9707L68.7175 91.9205Z"
                    fill={color1}
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M66.1548 87.3075L46.6778 87.8201L47.7029 89.3577H67.6924L66.1548 87.3075Z"
                    fill={color1}
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M63.0795 83.2071H43.6025L44.6276 84.7448H64.6171L63.0795 83.2071Z"
                    fill={color1}
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M60.0041 78.5941H40.5272L41.5523 80.1318H61.5418L60.0041 78.5941Z"
                    fill={color1}
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M56.9288 74.4937H37.4518L38.4769 75.5188H58.4665L56.9288 74.4937Z"
                    fill={color1}
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M53.8535 70.3933H34.3765L35.4016 71.4184H55.3912L53.8535 70.3933Z"
                    fill={color1}
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M97.5667 96.0917V113.046M97.5667 96.0917L102.217 101.229M97.5667 96.0917L72.25 66.6324M49 39.578V53.4495M49 39.578V38.0367L52.1 38.5505L63.9833 35.3351M49 39.578L72.25 66.6324M97.5667 113.046V130L72.25 95.9879M97.5667 113.046L49 53.4495M49 53.4495V64.7523L72.25 95.9879M49.5167 48.8257H53.1333L128.567 32.3853L142 35.4679V21.5963L128.05 18L120.3 20.097M63.9833 35.3351V61.156M63.9833 35.3351L88.7833 28.6247M88.7833 28.6247L89.3 57.0459M88.7833 28.6247L120.3 20.097M120.3 20.097L120.817 52.422M72.25 66.6324V95.9879"
                    stroke={color3}
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M75.4397 97.4696L51.3287 64.9392M75.4397 97.4696L79 102.044M75.4397 97.4696V112.718M29.6638 38L20 41.0497M29.6638 38L51.3287 64.9392M29.6638 38V50.7072M20 41.0497V52.9945M20 41.0497L37.8017 42.5746V53.7569M29.6638 63.4144L20 64.9392V52.9945M29.6638 63.4144L51.3287 94.9282M29.6638 63.4144V50.7072M51.3287 94.9282L75.4397 130V128.475V112.718M51.3287 94.9282V64.9392M29.6638 50.7072L20 52.9945M29.6638 50.7072L75.4397 112.718M20 52.9945L37.8017 53.7569M37.8017 53.7569V63.4144"
                    stroke={color4}
                />
            </g>
            <defs>
                <clipPath id="clip0_63_16">
                    <rect width="150" height="150" rx="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Floor
