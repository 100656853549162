import React from 'react'

import { useState, useEffect, useContext } from 'react'
import PopupContext from '../context/popupContext'
import axios from 'axios'

import './Form.scss'
import './Button.scss'

function Form() {
    const [nameState, setNameState] = useState()
    const [phoneState, setPhoneState] = useState()

    const { currentPopup, setCurrentPopup } = useContext(PopupContext)

    const handleBackdropClick = () => {
        setCurrentPopup({ content: currentPopup.content, isActive: false })
        setTimeout(() => {
            setCurrentPopup({ content: '', isActive: false })
        }, 300)
    }

    function nameChangeHandler(text) {
        setNameState(text)
    }

    function phoneChangeHandler(phone) {
        setPhoneState(phone)
    }

    const [fullForm, setFullForm] = useState('')

    useEffect(() => {
        const message = `<b>Новая заявка от</b> ${nameState}%0A<b>Номер телефона:</b> ${phoneState}`
        setFullForm(message)
    }, [nameState, phoneState])

    const chatID = process.env.REACT_APP_CHAT_ID
    const botToken = process.env.REACT_APP_BOT_TOKEN

    const CAPIToken = process.env.REACT_APP_CAPI_TOKEN
    const pixelID = process.env.REACT_APP_PIXEL_ID
    const APIVersion = process.env.REACT_APP_API_VERSION

    function handleSubmit(evt) {
        evt.preventDefault()
        // if (!captchaValue) {
        //     alert('Подтвердите, что вы не робот')
        //     return
        // }

        console.log(fullForm)

        const botUrl = `https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatID}&parse_mode=html&text=${fullForm}`
        // axios.post(botUrl, 'message').then((response) => console.log(response.status))

        const data = {
            data: [
                {
                    event_name: 'Purchase',
                    event_time: 1726476755,
                    action_source: 'website',
                    user_data: {
                        em: ['7b17fb0bd173f625b58636fb796407c22b3d16fc78302d79f0fd30c2fc2fc068'],
                        ph: [null],
                    },
                    custom_data: {
                        currency: 'USD',
                        value: '142.52',
                    },
                },
            ],
        }

        const CAPIURL = `https://graph.facebook.com/${APIVersion}/${pixelID}/events?access_token=${CAPIToken}`
        console.log(CAPIURL)
        axios.post(CAPIURL, data, {"test_event_code": "TEST87040"}).then((response) => console.log(response))

        handleBackdropClick()
    }

    console.log(`https://graph.facebook.com/${APIVersion}/${pixelID}/events?access_token=${CAPIToken}`)

    return (
        <form className="form-contacts" onSubmit={handleSubmit}>
            <div className="header-style" style={{ fontSize: '2rem' }}>
                Оставьте номер и мы вас проконсультируем
            </div>

            <div className="input-container">
                <label htmlFor="name">
                    Ваше имя
                    <input type="text" id="name" name="name" onChange={(evt) => nameChangeHandler(evt.target.value)} />
                </label>
            </div>

            <div className="input-container">
                <label htmlFor="phone">
                    Ваш номер телефона
                    <input type="tel" id="phone" name="phone" onChange={(evt) => phoneChangeHandler(evt.target.value)} required />
                </label>
            </div>

            <button type="submit" className="btn btn--white btn--white--bd-black">
                Свяжитесь со мной
            </button>
        </form>
    )
}

export default Form
