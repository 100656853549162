import './Footer.scss'

import logoSimple from '../images/logo-horizontal-full--dark.svg'

import { FaFacebookF } from 'react-icons/fa'
import { SiInstagram } from 'react-icons/si'

import { Link } from 'react-router-dom'

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-logo">
                <img src={logoSimple} alt="Company Logo" className="footer-logo" />
            </div>
            <div className="footer-container">
                <Link to="/">Домашняя страница</Link>
                <Link to="/about">О нас</Link>
                <Link to="/#what-we-do">Что мы делаем?</Link>
                <Link to="/contacts">Контакты</Link>
            </div>

            <div className="footer-container">
                <Link to="#">
                    <div className="footer-container__social-media">
                        <FaFacebookF />
                        <div className="footer-social-media-name">Facebook</div>
                    </div>
                </Link>

                <Link to="#">
                    <div className="footer-container__social-media">
                        <SiInstagram />
                        <div className="footer-social-media-name">Instagram</div>
                    </div>
                </Link>
            </div>

            <div className="footer-info">
                <div>&copy; 2024 СтальГрад. All rights reserved.</div>{' '}
                <br />
                <div>
                    <Link to="/privacy-policy">Политика конфиденциальности</Link>
                </div>
            </div>
        </footer>
    )
}

export default Footer
