import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

const ScrollToTopOnPageChange = () => {
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        const containsAnchor = location.hash !== ''
        // console.log(containsAnchor)

        if (!containsAnchor) {
            window.scrollTo(0, 0)
        } else {
          // console.log('hash')
        }
    }, [navigate, location])

    return null
}

export default ScrollToTopOnPageChange
