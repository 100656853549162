import { NavLink } from 'react-router-dom'
import './Menu.scss'

import { useContext, useEffect, useState } from 'react'
import PopupContext from '../context/popupContext'
import Form from './Form'
import { GiHamburgerMenu } from 'react-icons/gi'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect'

import logoSimple from '../images/logo-horizontal-full--light.svg'

import { HashLink } from 'react-router-hash-link';

function Menu() {
    const { currentPopup, setCurrentPopup } = useContext(PopupContext)
    const popupContent = <Form />

    const handleBackdropClick = () => {
        setCurrentPopup({ content: currentPopup.content, isActive: false })
        setTimeout(() => {
            setCurrentPopup({ content: '', isActive: false })
        }, 300)
    }

    const [isSticky, setIsSticky] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerWidth >= 768) {
                if (window.scrollY > 0) {
                    setIsSticky(true)
                } else {
                    setIsSticky(false)
                }
            } else {
                setIsSticky(false)
            }
        }

        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <>
            <div className="menu-fake"></div>
            <nav className={isSticky ? 'sticky-menu' : ''}>
                <NavLink
                    className={({ isActive }) => (isActive ? 'activeLink' : 'link')}
                    to="/"
                    end
                    onClick={(evt) => {
                        handleBackdropClick()
                    }}
                >
                    <img src={logoSimple} alt="Company logo" className='menu__company-logo'/>
                    {/* 123 */}
                </NavLink>
                <NavLink
                    className={({ isActive }) => (isActive ? 'activeLink' : 'link')}
                    to="/"
                    end
                    onClick={(evt) => {
                        handleBackdropClick()
                    }}
                >
                    Главная
                </NavLink>

                <HashLink
                    // className={({ isActive }) => (isActive ? 'activeLink' : 'link')}

                    className='link'
                    to="/#what-we-do"
                    // end
                    onClick={(evt) => {
                        handleBackdropClick()
                    }}
                >
                    Что мы делаем?
                </HashLink>

                <NavLink
                    className={({ isActive }) => (isActive ? 'activeLink' : 'link')}
                    to="/contacts"
                    end
                    onClick={(evt) => {
                        handleBackdropClick()
                    }}
                >
                    Контакты
                </NavLink>

                <NavLink
                    className={({ isActive }) => (isActive ? 'activeLink' : 'link')}
                    to="/about"
                    end
                    onClick={(evt) => {
                        handleBackdropClick()
                    }}
                >
                    О нас
                </NavLink>
                <div
                    className="btn btn--sm btn--white"
                    onClick={(evt) => {
                        setCurrentPopup({ content: popupContent, isActive: true })
                    }}
                >
                    Свяжитесь со мной
                </div>
            </nav>
        </>
    )
}

export default Menu
