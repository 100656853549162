import React from 'react'
import { motion, AnimationProps } from 'framer-motion'

const shapeVariations: AnimationProps = {
    transition: {
        delay: 1,
        duration: 0.5,
        ease: 'easeIn',
    },
    variants: {
        visible: {
            fillOpacity: 1,
            pathLength: 1,
            transition: {
                delay: 0.5,
                duration: 1,
            },
        },
        hidden: {
            fillOpacity: 0,
            pathLength: 0,
        },
    },
}

const mainPathVariant: AnimationProps = {
    transition: {
        duration: 1,
        ease: 'easeInOut',
    },
    variants: {
        visible: {
            pathLength: 1,
        },
        hidden: {
            pathLength: 0,
        },
    },
}

function Roof({ className, color1, color2, color3, color4, colorRect }) {
    const stokeStairColor = color1
    const fillStailrColor = color2

    return (
        <svg fill="none" className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150">
            <g clipPath="url(#clip0_63_16)">
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M72.0909 71L8 59.4484C10.6008 50.2804 38.0949 30.7615 67.6324 29.1941C109.096 26.9938 139.154 44.0462 149 52.8474L137.296 69.8998C132.838 67.6995 120.466 59.2283 102.186 58.3482C83.9059 57.4681 75.4348 66.416 72.0909 71Z"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                    fill={fillStailrColor}
                    // stroke={stokeStairColor}
                    // stroke={stokeStairColor}
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M7 59.9092C21.7714 41.2766 45.0476 32.1822 62.9524 29.4095C114.988 25.5277 145.202 50.482 148 52.1456C147.067 53.6244 146.657 54.0311 145.762 54.9183C130.095 43.8275 94.2857 29.964 62.9524 33.8458C41.2699 36.532 22.6667 50.482 14.2738 61.0183M7 59.9092C9.2381 60.4637 13.7143 61.4474 14.2738 61.0183M7 59.9092L14.2738 61.0183M24.3452 62.6819C42.8095 44.9366 77.5 21.0913 144.083 58.2456M38.3333 65.4546C64.0714 40.2305 106.036 38.2821 140.726 61.5728M48.9643 67.6728C66.3095 52.7002 97.0833 39.3912 138.488 64.3455M58.4762 68.7818C69.6667 54.7658 107.714 45.4911 136.25 66.5637M65.75 69.8909C77.7741 57.1365 104.917 49.9275 135.69 68.2273M71.3452 71C83.6548 59.9092 101.56 51.5911 134.571 68.7818"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                    stroke={stokeStairColor}
                    // fill={fillStailrColor}
                    // stroke={stokeStairColor}
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M21.1045 63L20 115.442L21.1045 116L23.3134 115.442L24.4179 63.5579L23.3134 63H21.1045Z"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                   fill={color3}
                    //fill={color3}
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M54.791 70.2526L53.6866 104.842L54.791 105.4L55.8955 104.284L57 70.2526H55.8955H54.791Z"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                   fill={color3}
                    //fill={color3}
                />

                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M71 100V71H73V99.4423L71.6667 100H71Z"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                   fill={color3}
                    //fill={color3}
                />

                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M5.24 59L3 59.5385V61.1538H5.24L73 73V70.8462L5.24 59Z"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                   fill={color3}
                    //fill={color3}
                />

                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M134 68.9091L147.571 52L153 53.6364L138.886 70L134 68.9091Z"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                   fill={color3}
                    //fill={color3}
                />

                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M133 111L133.625 69L138 69.56L137.375 111H133Z"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                   fill={color3}
                    //fill={color3}
                />

                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M145 129V55.9343L150 52V129H145Z"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                   fill={color3}
                    //fill={color3}
                />

                <motion.rect
                    x="5"
                    y="5"
                    width="140"
                    height="140"
                    rx="11"
                    stroke={colorRect}
                    strokeWidth="10"
                    fill="none"
                    {...mainPathVariant}
                    initial="hidden"
                    animate="visible"
                />
            </g>
            <defs>
                <clipPath id="clip0_63_16">
                    <rect width="150" height="150" rx="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Roof
