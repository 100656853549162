import React from 'react'

import './Button.scss'

function Button({el, color, onClickFunction}) {
  return (
    <button className={`btn btn--${color}`} onClick={() => {
      onClickFunction(el)
    }}>
        
        <div>{el.name}</div>

    </button>
  )
}

export default Button