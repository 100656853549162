import React from 'react'
import { motion, AnimationProps } from 'framer-motion'

const shapeVariations: AnimationProps = {
    transition: {
        delay: 1,
        duration: 0.5,
        ease: 'easeIn',
    },
    variants: {
        visible: {
            fillOpacity: 1,
            pathLength: 1,
            transition: {
                delay: 0.5,
                duration: 1,
            },
        },
        hidden: {
            fillOpacity: 0,
            pathLength: 0,
        },
    },
}

const mainPathVariant: AnimationProps = {
    transition: {
        duration: 1,
        ease: 'easeInOut',
    },
    variants: {
        visible: {
            pathLength: 1,
        },
        hidden: {
            pathLength: 0,
        },
    },
}

function Stair({className, color1, color2, color3, color4, colorRect}) {
    const stokeStairColor = color1
    const fillStailrColor = color2

    return (
        <svg fill="none" className={className} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 150 150">
            <g clipPath="url(#clip0_63_16)">
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M23 102.412V93.7843L81.9543 119.667L102.085 111.039V119.667L81.9543 128.294L23 102.412Z"
                    strokeMiterlimit="10"
                    // strokeWidth="1"
                    fill={fillStailrColor}
                    // stroke={stokeStairColor}
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M63.2614 67.902V59.2745L122.216 85.1569L142.346 76.5294V85.1569L122.216 93.7843L63.2614 67.902Z"
                    strokeMiterlimit="10"
                    // strokeWidth="1"
                    fill={fillStailrColor}
                    // stroke={stokeStairColor}
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M103.523 33.3922V24.7647L162.477 50.6471L182.608 42.0196V50.6471L162.477 59.2745L103.523 33.3922Z"
                    strokeMiterlimit="10"
                    // strokeWidth="1"
                    fill={fillStailrColor}
                    // stroke={stokeStairColor}
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M43.1307 85.1569V76.5294L102.085 102.412L122.216 93.7843V102.412L102.085 111.039L43.1307 85.1569Z"
                    strokeMiterlimit="10"
                    // strokeWidth="1"
                    fill={fillStailrColor}
                    // stroke={stokeStairColor}
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M83.3922 50.6471V42.0196L142.346 67.902L162.477 59.2745V67.902L142.346 76.5294L83.3922 50.6471Z"
                    strokeMiterlimit="10"
                    // strokeWidth="1"
                    fill={fillStailrColor}
                    // stroke={stokeStairColor}
                />
                <motion.path
                    {...shapeVariations}
                    initial="hidden"
                    animate="visible"
                    d="M123.654 16.1373V7.5098L182.608 33.3922L202.739 24.7647V33.3922L182.608 42.0196L123.654 16.1373Z"
                    strokeMiterlimit="10"
                    // strokeWidth="1"
                    fill={fillStailrColor}
                    // stroke={stokeStairColor}
                />
                <motion.rect
                    x="5"
                    y="5"
                    width="140"
                    height="140"
                    rx="11"
                    stroke={colorRect}
                    strokeWidth="10"
                    fill="none"
                    {...mainPathVariant}
                    initial="hidden"
                    animate="visible"
                />
            </g>
            <defs>
                <clipPath id="clip0_63_16">
                    <rect width="150" height="150" rx="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Stair
