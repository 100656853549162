import React, { useState, useEffect } from 'react';
import './Home.scss';
import './FormBlock.scss';

import slogan from '../images/slogan.svg';
import WhatWeDo from './WhatWeDo';
import Form from './Form';
import backgroundImagePath from '../images/home-background.webp';
import logoUSM from '../images/logo-horizontal-full--light--usm.svg';

import { AnimatePresence, motion } from 'framer-motion';
import Stair from './icons/Stair';
import GlassCarnis from './icons/GlassCarnis';
import Floor from './icons/Floor';
import Roof from './icons/Roof';

function Home() {
  const [backgroundImage, setBackgroundImage] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  const icons = [
    { icon: <GlassCarnis className="icon-container__icon" color1='#FF9914' color2='rgba(217,217,217, .3)' colorRect="#fff"/>, text: 'Стеклянные козырьки' },
    { icon: <Stair className="icon-container__icon" color1='#fff' color2='#FF9914' colorRect="#fff"/>, text: 'Металлические лестницы' },
    { icon: <Floor className="icon-container__icon" color1='#fff' color2='#FF9914' color3='#FF9914' color4='#FF9914' colorRect="#fff"/>, text: 'Антресольные этажи' },
    { icon: <Roof className="icon-container__icon" color1='#fff' color2='#FF9914' color3='#fff' color4='#FF9914' colorRect='#fff'/>, text: 'Навесы' },
    
  ];

  useEffect(() => {
    const preloadImage = new Image();
    preloadImage.src = backgroundImagePath; // Устанавливаем путь к изображению
    preloadImage.onload = () => {
      setBackgroundImage(backgroundImagePath);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % icons.length);
    }, 3000); // меняем элемент каждые 3 секунды

    return () => clearInterval(interval);
  }, [icons.length]);

  return (
    <>
      <div className="block-background home-background" loading="lazy">
        <div className="block-wrapper block-wrapper--home">
          <div className="home-info">
            <h1 className="home-header">
              <img src={logoUSM} alt="logo ultraSM" className="home-background__logo-usm" />
              СтальГрад
            </h1>
            <ul>
                <li>гарантия год</li>
                <li>сроки от 1 месяца</li>
                <li>любые проекты под ключ</li>
            </ul>


            <img src={slogan} alt="Company Logo -- mini" className="slogan" />

            <div className="icons-list">
              <AnimatePresence mode='wait'>
                <motion.div
                  key={currentIndex}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.5 }}
                  className="icon-container"
                >
                  {icons[currentIndex].icon}
                  <div className="icon-container__text">{icons[currentIndex].text}</div>
                </motion.div>
              </AnimatePresence>
            </div>
          </div>

          <Form />
        </div>
      </div>

      <div className="block-background block-whatwedo-background" id="what-we-do">
        <div className="block-wrapper">
          <WhatWeDo />
        </div>
      </div>

      <div className="block-background form-block-background">
        <div className="block-wrapper ">
          <Form />
        </div>
      </div>
    </>
  );
}

export default Home;
